import { api } from "@taskpane/shared/api";
import { getAllLinks } from "@taskpane/plugin/word/link/get-all-links";
import { documentUrlParser } from "@taskpane/plugin/document";

interface ApiResult {
  data: {
    id: string;
    url: string;
    documentId: number;
    title: string;
    statusInfo: {
      type: "ACTIVE" | "EXPIRED" | "CHANGED";
      title: string;
      urls: {
        title: string;
        url: string;
      }[];
    };
    entries: {
      id: string;
      entryId: number;
      title: string;
      url: string;
      statusInfo: {
        type: "ACTIVE" | "EXPIRED" | "CHANGED";
        title: string;
        urls: {
          title: string;
          url: string;
        }[];
      };
    }[];
    linksCount: number;
    fragmentsCount: number;
  }[];
}

export async function getAllByIds(ids: string[], verificationId?: string) {
  const verification = verificationId !== undefined ? `?verificationId=${verificationId}` : "";
  const result = await api.post<unknown, ApiResult>("/private/plugin/documents" + verification, ids);
  const wordLinks = await getAllLinks();

  return {
    data: result.data.map((document) => ({
      ...document,
      linksCount: wordLinks.filter((link) => documentUrlParser.isEqualByDocument(document.url, link.url)).length,
      fragmentsCount: document.entries.length,
    })),
  } satisfies ApiResult;
}
