import React, { useRef } from "react";
import { Card, makeStyles, mergeClasses, shorthands, tokens, typographyStyles } from "@fluentui/react-components";
import { Button } from "@taskpane/plugin/ui/fragment";
import { ChevronLeft, ChevronRight, OverlayTimer } from "@taskpane/shared/ui";
import { useTimer } from "@taskpane/shared/hook";

const useStyles = makeStyles({
  root: {
    backgroundColor: tokens.colorNeutralBackground1,
    gap: tokens.spacingVerticalM,
    ...shorthands.padding(tokens.spacingVerticalM, tokens.spacingHorizontalM),
    "&:hover": {
      backgroundColor: tokens.colorNeutralBackground2Hover,
    },
    "&:active": {
      backgroundColor: tokens.colorNeutralBackground2Pressed,
    },
  },

  rootSelected: {
    backgroundColor: tokens.colorNeutralBackground1Selected,
  },

  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: "10px 5px",
  },

  navigation: {
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },

  navigationIcon: {
    width: "32px",
    height: "32px",
    display: "flex",
    flexShrink: 0,
    alignItems: "center",
    justifyContent: "center",
    ...shorthands.padding(tokens.spacingVerticalSNudge, tokens.spacingHorizontalSNudge),
  },

  content: {
    gap: tokens.spacingVerticalS,
  },

  contentContent: {
    gap: "2px",
    ...typographyStyles.body1Strong,
  },

  contentTitle: {
    height: "20px",
    gap: "10px",
    ...typographyStyles.caption1Stronger,
  },

  action: {
    width: "100%",
    ...shorthands.padding("6px", "8px"),
  },
});

const DELETION_DELAY_MS = 5000;

export function Fragment({
  title,
  content,
  status,
  citationCount,
  citationTotalCount,
  onContentSelect,
  selected = true,
  onRemoveLink,
  onRemoveAllReference,
  onNextReference,
  onPrevReference,
}: {
  title: string;
  content: string;
  status?: React.ReactElement;
  citationCount: number;
  citationTotalCount: number;
  selected: boolean;
  onContentSelect: () => void;
  onRemoveLink: () => void;
  onRemoveAllReference: () => void;
  onNextReference: () => void;
  onPrevReference: () => void;
}): React.JSX.Element {
  const styles = useStyles();

  const timerId = useRef(Math.random().toString()).current;
  const { createTimer, cancel, timers } = useTimer();

  const handleRemoveLink = () => {
    createTimer(timerId, onRemoveLink, DELETION_DELAY_MS, "Вернуть ссылку на фрагмент");
  };

  const handleRemoveAllReference = () => {
    createTimer(timerId, onRemoveAllReference, DELETION_DELAY_MS, "Вернуть все упоминания");
  };

  return (
    <OverlayTimer
      delay={DELETION_DELAY_MS}
      time={timers[timerId]?.timeLeft ?? 0}
      isRunning={timers[timerId]?.isRunning ?? false}
      onCancel={() => cancel(timerId)}
      actionText={timers[timerId]?.cancelText}
    >
      <Card
        className={mergeClasses(styles.root, selected ? styles.rootSelected : "")}
        appearance={"filled-alternative"}
        onClick={onContentSelect}
      >
        <div className={styles.container}>
          <div className={styles.navigation}>
            <div
              className={styles.navigationIcon}
              onClick={(event) => {
                event.stopPropagation();
                if (citationCount !== 1) {
                  onPrevReference();
                }
              }}
            >
              <ChevronLeft />
            </div>
            <div>{`${citationCount}/${citationTotalCount} упоминание фрагмента в тексте`}</div>
            <div
              className={styles.navigationIcon}
              onClick={(event) => {
                event.stopPropagation();
                if (citationCount < citationTotalCount) {
                  onNextReference();
                }
              }}
            >
              <ChevronRight />
            </div>
          </div>
          <div className={styles.content} onClick={onContentSelect}>
            <div className={styles.contentTitle}>{title}</div>
            <div className={styles.contentContent}>{content}</div>
          </div>
          {status}
          {selected && (
            <>
              <Button variant={"secondary"} onClick={handleRemoveLink}>
                Удалить ссылку
              </Button>
              <Button variant={"subtle"} onClick={handleRemoveAllReference}>
                Удалить все упоминания
              </Button>
            </>
          )}
        </div>
      </Card>
    </OverlayTimer>
  );
}
