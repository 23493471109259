import Cookies from "js-cookie";

const COOKIE_NAME = "verifications";

interface Verification {
  documentId: string;
  verificationId: string;
}

export function getVerifications(): Verification[] {
  const data = Cookies.get(COOKIE_NAME);
  return data ? JSON.parse(data) : [];
}
export function addVerificationData(verification: Verification): void {
  const existingData = getVerifications();
  const existingVerificationIndex = existingData.findIndex((data) => data.documentId === verification.documentId);

  if (existingVerificationIndex !== -1) {
    existingData[existingVerificationIndex] = verification;
  } else {
    existingData.push(verification);
  }

  Cookies.set(COOKIE_NAME, JSON.stringify(existingData));
}

export function getVerificationByDocumentId(documentId: string): Verification | null {
  const existingData = getVerifications();
  return existingData.find((data) => data.documentId === documentId) ?? null;
}
export function clearVerifications(): void {
  Cookies.remove(COOKIE_NAME);
}
