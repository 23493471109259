import React from "react";
import { makeStyles } from "@fluentui/react-components";
import { ChoiceDocument } from "./choice-document";
import { SelectedDocument } from "../model/selected-document";
import { Link } from "../model/link";
import { DocumentStatus } from "@taskpane/plugin/document";

const useStyles = makeStyles({
  list: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: "12px",
  },
});

export function DocumentList({
  link,
  selectedDocuments,
  chooseDocument,
}: {
  link: Link;
  selectedDocuments: SelectedDocument[];
  chooseDocument: (document: SelectedDocument) => void;
}): React.JSX.Element {
  const styles = useStyles();
  return (
    <div className={styles.list}>
      {link.documents.map((document) => (
        <ChoiceDocument
          key={document.id}
          content={document.title}
          selected={Boolean(
            selectedDocuments.find((existing) => existing.oldId === link.id && existing.newUrl === document.url)
          )}
          status={
            document.status !== null ? (
              <DocumentStatus
                variant={document.status.type.toLowerCase() as "active" | "changed" | "expired"}
                title={document.status.title}
                urls={document.status.urls}
              />
            ) : undefined
          }
          onSelect={() =>
            chooseDocument({
              oldId: link.id,
              oldUrl: link.url,
              newUrl: document.url,
              newUrlTitle: document.title,
            })
          }
        />
      ))}
    </div>
  );
}
