import { getAllLinks } from "@taskpane/plugin/word/link/get-all-links";
import { documentUrlParser } from "@taskpane/plugin/document";
import { getAllByIds as getAllPublicByIds } from "@taskpane/plugin/api/endpoints/public/documents/get-all-by-ids";
import { getAllByIds as getAllPrivateByIds } from "@taskpane/plugin/api/endpoints/private/documents/get-all-by-ids";
import { unknownUrlParser } from "@taskpane/plugin/links";
import { getVerificationByDocumentId } from "@taskpane/plugin/service/verification/verifications-store";
import { getId } from "@taskpane/plugin/word/document/get-id";

type Result = {
  linksCount: number;
  unknownLinksCount: number;
  docsCount: number;
  activeCount: number;
  expiredCount: number;
  changedCount: number;
  isVerified: boolean;
};

export async function all(isAuthenticated: boolean = false): Promise<Result> {
  const wordLinks = await getAllLinks();
  const documentLinks = wordLinks.filter((link) => documentUrlParser.isAllow(link.url));
  const documentIds: string[] = documentLinks.map((link) => documentUrlParser.parse(link.url).uuid);
  const currentDocumentId = await getId();
  const verification = await getVerificationByDocumentId(currentDocumentId);
  let documents = [];

  if (!isAuthenticated) {
    documents = (await getAllPublicByIds(documentIds)).data;
  } else {
    const documentId = await getId();
    const verification = await getVerificationByDocumentId(documentId);
    documents = (await getAllPrivateByIds(documentIds, verification !== null ? verification.verificationId : undefined))
      .data;
  }

  const unknownLinksIds: string[] = wordLinks
    .filter((link) => unknownUrlParser.isAllow(link.url))
    .map((link) => unknownUrlParser.parse(link.url).uuid);

  const uniqueDocuments = documents.filter((doc, index, self) => index === self.findIndex((d) => d.id === doc.id));

  const documentCountByStatus: {
    active: number;
    changed: number;
    expired: number;
  } = uniqueDocuments.reduce(
    (acc, document) => {
      const status = document.statusInfo?.type.toLowerCase() as "active" | "changed" | "expired";
      acc[status] = (acc[status] || 0) + 1;
      return acc;
    },
    {
      active: 0,
      changed: 0,
      expired: 0,
    }
  );

  return {
    linksCount: documentIds.length,
    unknownLinksCount: Array.from(new Set(unknownLinksIds)).length,
    docsCount: uniqueDocuments.length,
    activeCount: documentCountByStatus.active,
    changedCount: documentCountByStatus.changed,
    expiredCount: documentCountByStatus.expired,
    isVerified: verification !== null,
  };
}
