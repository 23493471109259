import React from "react";
import { makeStyles, mergeClasses, shorthands, tokens, typographyStyles, Button } from "@fluentui/react-components";
import { LinkIcon } from "./icons/link-icon";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.borderWidth("1px"),
    ...shorthands.borderStyle("solid"),
    ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalS),
    ...shorthands.textDecoration("none"),
    ...shorthands.gap("8px"),
  },

  rootSquare: {
    padding: "10px 8px",
    height: "40px",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    ...shorthands.borderWidth("1px", 0),
  },

  rootRounded: {
    ...shorthands.borderRadius(tokens.borderRadiusMedium),
  },

  title: {
    ...typographyStyles.caption1Strong,
    ...shorthands.padding(0, tokens.spacingHorizontalSNudge),
  },

  titleActive: {
    color: tokens.colorStatusSuccessForeground3,
  },

  titleEdited: {
    color: tokens.colorStatusWarningForeground3,
  },

  titleUpdated: {
    color: tokens.colorStatusWarningForeground3,
  },

  titleExpired: {
    color: tokens.colorStatusDangerForeground3,
  },

  content: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    color: tokens.colorNeutralForeground3,
    ...typographyStyles.caption2Strong,
    ...shorthands.padding(0, tokens.spacingHorizontalSNudge),
  },

  active: {
    backgroundColor: tokens.colorStatusSuccessBackground1,
    ...shorthands.borderColor(tokens.colorStatusSuccessBorder1),
  },

  changed: {
    backgroundColor: tokens.colorStatusWarningBackground1,
    ...shorthands.borderColor(tokens.colorStatusWarningBorder1),
  },

  expired: {
    backgroundColor: tokens.colorStatusDangerBackground1,
    ...shorthands.borderColor(tokens.colorStatusDangerBorder1),
  },
});

export function DocumentStatus({
  variant,
  title,
  shape = "rounded",
  urls,
}: {
  variant: "active" | "changed" | "expired";
  title: string;
  urls: {
    title: string;
    url: string;
  }[];
  shape?: "square" | "rounded";
}): React.JSX.Element {
  const styles = useStyles();

  return (
    <div
      className={mergeClasses(
        styles.root,
        {
          active: styles.active,
          changed: styles.changed,
          expired: styles.expired,
        }[variant],
        {
          square: styles.rootSquare,
          rounded: styles.rootRounded,
        }[shape]
      )}
    >
      <div
        className={mergeClasses(
          styles.title,
          {
            active: styles.titleActive,
            changed: styles.titleUpdated,
            expired: styles.titleExpired,
          }[variant]
        )}
      >
        {title}
      </div>
      {urls.map((url) => (
        <Button key={url.url} as="a" icon={<LinkIcon />} appearance="subtle" href={url.url} target="_blank">
          {url.title}
        </Button>
      ))}
    </div>
  );
}
