import React, { useEffect } from "react";
import { PrivateUnknownLinks } from "@taskpane/plugin/links";
import { useAuth } from "@taskpane/auth";
import { MainLayout } from "@taskpane/shared/layout";
import { StatusScreen } from "@taskpane/plugin/ui/status-screen";
import { Link } from "@fluentui/react-components";
import { useNavigate } from "react-router-dom";
import { useActions } from "@taskpane/infrastructure/actions";

export default function Page(): React.JSX.Element {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const { addAction, clearActions } = useActions();

  useEffect(() => {
    if (!isAuthenticated) {
      addAction({
        id: "access-unknown-links",
        title: "Оформить пробный доступ",
        variant: "primary",
        onClick: () => (window.location.href = "https://chkdoc.ru/access_form"),
      });
      addAction({
        id: "close-unknown-links",
        title: "Закрыть",
        onClick: () => navigate(-1),
      });
    }
    return () => {
      clearActions();
    };
  }, [isAuthenticated]);

  if (!isAuthenticated) {
    return (
      <MainLayout>
        <StatusScreen
          variant="unknown"
          title={"Уточнение ссылок"}
          text={"Доступно только для пользователей с уровнем «Авторизованный» или «ЧекДок Премиум»"}
          additionalText={
            "Чтобы у вас была возможность уточнить ссылки при наличии нескольких подходящих вариантов, оформите пробный доступ и используйте весь функционал расширения БЕСПЛАТНО"
          }
          actions={
            <>
              <Link onClick={() => navigate("/profile/account-level")}>Уровни</Link>
              <Link as="a" href="https://chkdoc.ru/premium/instruction#rec819818400">
                Подробнее
              </Link>
            </>
          }
        />
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <PrivateUnknownLinks />
    </MainLayout>
  );
}
