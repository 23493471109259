import { User } from "@taskpane/auth/provider/use-auth";

type UserInfoResponse = {
  aud: string[];
  auth_time: number;
  ext: {
    response: {
      params: {
        command_data: {
          client: {
            acc_value: number;
            club: boolean;
            company: string;
            email: string;
            first_name: string;
            host: string;
            id_cl: string;
            last_name: string;
            mid_name: string;
            tariff: string;
            username: string;
            utc: number;
          };
          error: {
            description: string;
            number: number;
          };
        };
        success_info: string;
      };
      success: number;
      version: number;
    };
  };
  iat: number;
  iss: string;
  rat: number;
  sub: string;
};

export async function getUserInfo(token: string): Promise<User | null> {
  const response = await fetch("https://account.garant.ru/userinfo", {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    await Promise.reject(new Error("Не удалось загрузить профиль"));
  }

  const data: UserInfoResponse = await response.json();

  const { client } = data.ext.response.params.command_data;
  const isPremium = client.tariff === "premium";

  return {
    id: client.id_cl,
    name: client.last_name + " " + client.first_name,
    lastName: client.last_name,
    firstName: client.first_name,
    email: client.email,
    isPremium,
  };
}
