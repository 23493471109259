import { Avatar } from "@fluentui/react-components";
import React, { useEffect } from "react";
import { useAuth } from "@taskpane/auth";
import { Link } from "react-router-dom";
import { useAlertContext } from "@taskpane/infrastructure/alert";

export function UserMenu(): React.JSX.Element {
  const { user, error } = useAuth();
  const alert = useAlertContext();
  const isPremium: boolean = user?.isPremium ?? false;

  useEffect(() => {
    if (error !== null) {
      alert.error(error);
    }
  }, [error]);

  return (
    <Link to="/profile">
      <Avatar
        active={isPremium ? "active" : "unset"}
        name={user?.name ?? ""}
        size={28}
        color={user !== null ? "brand" : "neutral"}
      />
    </Link>
  );
}
