import React from "react";
import { FilterType } from "../hook/use-document-filter/use-document-filter";
import { Filter } from "@taskpane/shared/ui";
import {
  Button,
  Menu,
  MenuTrigger,
  MenuList,
  MenuItemCheckbox,
  MenuPopover,
  makeStyles,
  SearchBox,
} from "@fluentui/react-components";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "4px",
  },
  searchBox: {
    width: "100%",
  },
  filter: {
    padding: "6px",
  },
});

export function DocumentFilter({
  setFilterCriteria,
  isFilter,
  isVerified,
}: {
  setFilterCriteria: (type: FilterType, value: string | string[]) => void;
  isFilter: boolean;
  isVerified: boolean;
}): React.JSX.Element {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <SearchBox
        className={styles.searchBox}
        placeholder="Искать"
        onChange={(_event, data) => setFilterCriteria("title", data.value)}
      />
      {isFilter && (
        <Menu onCheckedValueChange={(_e, { checkedItems }) => setFilterCriteria("status", checkedItems)}>
          <MenuTrigger>
            <Button appearance={"subtle"} icon={<Filter className={styles.filter} fontSize={20} />} />
          </MenuTrigger>
          <MenuPopover>
            <MenuList>
              <MenuItemCheckbox name="status" value="active">
                Действуют
              </MenuItemCheckbox>
              {isVerified && (
                <MenuItemCheckbox name="status" value="changed">
                  Изменились
                </MenuItemCheckbox>
              )}
              <MenuItemCheckbox name="status" value="expired">
                Утратили силу
              </MenuItemCheckbox>
            </MenuList>
          </MenuPopover>
        </Menu>
      )}
    </div>
  );
}
