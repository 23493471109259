import { getAllByIds } from "@taskpane/plugin/api/endpoints/private/links/get-all-by-ids";
import { getAllLinks } from "@taskpane/plugin/word/link/get-all-links";
import { unknownUrlParser } from "@taskpane/plugin/links";

export async function getPrivateLinks() {
  const wordLinks = await getAllLinks();
  const linkIds: string[] = wordLinks
    .filter((link) => unknownUrlParser.isAllow(link.url))
    .map((link) => unknownUrlParser.parse(link.url).uuid);

  if (linkIds.length === 0) {
    return [];
  }

  const links = await getAllByIds(linkIds);

  return links.data.map((link) => ({
    ...link,
    documents: link.documents.map(({ statusInfo, ...document }) => ({
      ...document,
      status: statusInfo,
    })),
  })) satisfies {
    id: string;
    url: string;
    text: string;
    documents: Array<{
      id: string;
      documentId: number;
      title: string;
      url: string;
      status: {
        type: "active" | "changed" | "expired";
        title: string;
        urls: {
          title: string;
          url: string;
        }[];
      } | null;
    }>;
  }[];
}
