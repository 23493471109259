import { getAllByIds } from "@taskpane/plugin/api/endpoints/private/documents/get-all-by-ids";
import { getAllLinks } from "@taskpane/plugin/word/link/get-all-links";
import { documentUrlParser } from "@taskpane/plugin/document";

export async function all({ verificationId }: { verificationId?: string }) {
  const wordLinks = await getAllLinks();
  const ids: string[] = wordLinks
    .filter((link) => documentUrlParser.isAllow(link.url))
    .map((link) => documentUrlParser.parse(link.url).uuid);

  if (ids.length === 0) {
    return [];
  }

  const documents = await getAllByIds(ids, verificationId);

  return documents.data.map((document) => ({
    ...document,
    status:
      document.statusInfo !== null
        ? {
            type: document.statusInfo.type.toLowerCase() as "active" | "changed" | "expired",
            title: document.statusInfo.title,
            urls: document.statusInfo.urls,
          }
        : null,
    entries: document.entries.map((entry) => {
      return {
        ...entry,
        status:
          entry.statusInfo !== null
            ? {
                type: entry.statusInfo.type.toLowerCase() as "active" | "changed" | "expired",
                title: entry.statusInfo.title,
                urls: entry.statusInfo.urls,
              }
            : null,
        references: wordLinks
          .filter((link) => documentUrlParser.isAllow(link.url) && entry.id === documentUrlParser.parse(link.url).uuid)
          .map((link) => ({
            content: link.title,
            url: link.url,
          })),
      };
    }),
  })) satisfies {
    id: string;
    url: string;
    documentId: number;
    title: string;
    status: {
      type: "active" | "changed" | "expired";
      title: string;
      urls: {
        title: string;
        url: string;
      }[];
    };
    entries: {
      id: string;
      entryId: number;
      title: string | null;
      url: string;
      status: {
        type: "active" | "changed" | "expired";
        title: string;
        urls: {
          title: string;
          url: string;
        }[];
      };
      references: {
        content: string;
        url: string;
      }[];
    }[];
  }[];
}
