import React from "react";
import { Analytics as AnalyticsUI, AnalyticItem } from "@taskpane/plugin/ui/analytics";
import { all as allAnalytics } from "@taskpane/plugin/action/analytics/all";
import { useQuery } from "react-query";
import { useAuth } from "@taskpane/auth";

export function Analytics() {
  const { user, isAuthenticated } = useAuth();
  const { data: analytics } = useQuery("/analytics", async () => await allAnalytics(isAuthenticated));
  return (
    <AnalyticsUI>
      <AnalyticItem variant="primary" title="Всего ссылок" quantity={String(analytics?.linksCount ?? "-")} />
      <AnalyticItem variant="default" title="Всего документов" quantity={String(analytics?.docsCount ?? "-")} />
      {user !== null && user?.isPremium && (
        <>
          <AnalyticItem
            variant="danger"
            title="Утратили силу"
            quantity={String(analytics?.expiredCount ?? "-")}
            fromTotal={String(analytics?.docsCount ?? "-")}
          />
          {analytics?.isVerified && (
            <AnalyticItem
              variant="warning"
              title="Изменились"
              quantity={String(analytics?.changedCount ?? "-")}
              fromTotal={String(analytics?.docsCount ?? "-")}
            />
          )}
          <AnalyticItem
            variant="success"
            title={analytics?.isVerified ? "Действуют без изменений" : "Действуют"}
            quantity={String(analytics?.activeCount ?? "-")}
            fromTotal={String(analytics?.docsCount ?? "-")}
          />
        </>
      )}
    </AnalyticsUI>
  );
}
