import React, { useContext, useEffect, useState } from "react";
import { AuthContext as OAuthContext } from "react-oauth2-code-pkce";
import { AuthContext, User } from "./auth-context";
import { getUserInfo } from "./api";

export function AuthWithUserProvider({ children }: { children?: React.ReactNode }): React.JSX.Element {
  const context = useContext(OAuthContext);
  const { token, logIn, logOut, error: errorContext } = context;
  const [user, setUser] = useState<User | null>(null);
  const [error, seError] = useState<string | null>(errorContext);
  const [loading, setLoading] = useState<boolean>(context.loginInProgress);

  useEffect(() => {
    if (token.length > 0) {
      setLoading(true);
      getUserInfo(token)
        .then(setUser)
        .catch((e) => {
          seError(e.message);
        })
        .finally(() => setLoading(false));
    } else {
      setUser(null);
    }
  }, [token]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: token.length > 0,
        token,
        user,
        signIn: logIn.bind("", undefined, undefined, "popup"),
        signOut: logOut,
        loading: loading,
        error: error,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
