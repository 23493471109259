import React from "react";
import { makeStyles, tokens, typographyStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexFlow: "row",
    gap: "4px",
    padding: "5px 12px",
    alignItems: "center",
    justifyContent: "center",
    color: tokens.colorStatusSuccessForeground1,
    ...typographyStyles.body1Strong,
  },
});

export function TariffStatusActive({
  children,
  icon,
}: {
  children: React.ReactNode;
  icon?: React.ReactElement;
}): React.JSX.Element {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      {icon}
      {children}
    </div>
  );
}
