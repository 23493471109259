import { api } from "@taskpane/shared/api";
import { getAllLinks } from "@taskpane/plugin/word/link/get-all-links";
import { documentUrlParser } from "@taskpane/plugin/document";

interface ApiResult {
  data: {
    id: string;
    url: string;
    documentId: number;
    title: string;
    statusInfo: null;
    entries: {
      id: string;
      entryId: number;
      title: string;
      url: string;
      statusInfo: null;
    }[];
    linksCount: number;
    fragmentsCount: number;
  }[];
}

export async function getAllByIds(ids: string[]) {
  const result = await api.post<unknown, ApiResult>("/plugin/documents", ids);
  const wordLinks = await getAllLinks();

  return {
    data: result.data.map((document) => ({
      ...document,
      linksCount: wordLinks.filter((link) => documentUrlParser.isEqualByDocument(document.url, link.url)).length,
      fragmentsCount: document.entries.length,
    })),
  } satisfies ApiResult;
}
