import { api } from "@taskpane/shared/api";

interface ApiResult {
  items: Array<{
    id: string;
    url: string;
    text: string;
    documents: Array<{
      id: string;
      documentId: number;
      title: string;
      url: string;
      statusInfo: {
        type: "active" | "changed" | "expired";
        title: string;
        urls: {
          title: string;
          url: string;
        }[];
      } | null;
    }>;
  }>;
}

interface Result {
  data: Array<{
    id: string;
    url: string;
    text: string;
    documents: Array<{
      id: string;
      documentId: number;
      title: string;
      url: string;
      statusInfo: {
        type: "active" | "changed" | "expired";
        title: string;
        urls: {
          title: string;
          url: string;
        }[];
      } | null;
    }>;
  }>;
}

export async function getAllByIds(ids: string[]) {
  const result = await api.post<unknown, ApiResult>("/private/plugin/links", ids);
  return {
    data: result.items,
  } satisfies Result;
}
