import React from "react";
import { Card, Button } from "@fluentui/react-components";
import { CheckmarkStarburst } from "@taskpane/shared/ui";
import { Option } from "./option";
import { Navigation } from "./navigaion";
import { TariffBlock } from "./tariff-block";
import { TariffStatusActive } from "@taskpane/plugin/ui/tariff/tariff-status-active";

type TariffVariant = "basic" | "authorized" | "checkdoc";

type OptionType = {
  title: string;
  available: TariffVariant[];
};

const options: OptionType[] = [
  {
    title: "Простановка ссылок в документе",
    available: ["basic", "authorized", "checkdoc"],
  },
  {
    title: "Удаление проставленных ссылок",
    available: ["basic", "authorized", "checkdoc"],
  },
  {
    title: "Уточнение документа для простановки ссылки",
    available: ["authorized", "checkdoc"],
  },
  {
    title: "Выбор версии системы ГАРАНТ для простановки ссылок",
    available: ["checkdoc"],
  },
  {
    title: "Информация о статусе и изменениях документов",
    available: ["checkdoc"],
  },
  {
    title: "Конвертация ссылок из одной версии системы ГАРАНТ в другую",
    available: ["checkdoc"],
  },
  {
    title: "Проверка актуальности документов на произвольную дату",
    available: ["checkdoc"],
  },
];

const tariffTranslations: Record<TariffVariant, string> = {
  basic: "Базовый",
  authorized: "Авторизованный",
  checkdoc: "ЧекДок Премиум",
};

export function Tariff({
  tariff,
  isActive,
  allowPrev,
  allowNext,
  onPrev,
  onNext,
}: {
  tariff: TariffVariant;
  isActive: boolean;
  allowPrev: boolean;
  allowNext: boolean;
  onPrev: () => void;
  onNext: () => void;
}): React.JSX.Element {
  const currentTariffTranslation = tariffTranslations[tariff];
  const getTariffLink = (tariff: TariffVariant) => {
    switch (tariff) {
      case "authorized":
        return "https://chkdoc.ru/access_form?utm_source=checkdoc_plug-in&utm_medium=landing&utm_campaign=lead-from-plugin#form";
      case "checkdoc":
        return "https://chkdoc.ru/premium";
      default:
        return "#";
    }
  };

  return (
    <TariffBlock>
      <Navigation
        currentTariff={currentTariffTranslation}
        allowPrev={allowPrev}
        allowNext={allowNext}
        onPrev={onPrev}
        onNext={onNext}
      />
      <Card>
        {options.map((option, index) => (
          <Option key={index} available={option.available.includes(tariff)}>
            {option.title}
          </Option>
        ))}
        {isActive ? (
          <TariffStatusActive icon={<CheckmarkStarburst />}>Активен</TariffStatusActive>
        ) : (
          <Button appearance="primary" onClick={() => (window.location.href = getTariffLink(tariff))}>
            Подключить
          </Button>
        )}
      </Card>
    </TariffBlock>
  );
}
