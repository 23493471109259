import React from "react";
import { makeStyles, typographyStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    textAlign: "center",
    padding: "36px 0",
    justifyContent: "center",
    height: "226px",
  },
  title: {
    ...typographyStyles.body1Strong,
  },
  description: {
    ...typographyStyles.body1,
  },
});

export function NoAccess() {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <div className={styles.title}>Нет доступа к системе?</div>
      <div className={styles.description}>
        Получите{" "}
        <a href="https://chkdoc.ru/access_form?utm_source=checkdoc_plug-in&utm_medium=landing&utm_campaign=lead-from-plugin#form">
          Пробный доступ
        </a>{" "}
        на 3 дня бесплатно.
      </div>
    </div>
  );
}
